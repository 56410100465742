const { freeze } = Object;

module.exports = freeze({
  // API: "http://localhost:3024",

  API: "https://tiendita-api.josefabio.com",
  BOT_USERNAME: "pzvvbrimssyxlhbaksnyfejjqnbtvBot",

  // API: "https://tiendita-api-demo.josefabio.com",
  // BOT_USERNAME: "pzvvbrimssyxlaksnyfejjqnbtvBot",
});
